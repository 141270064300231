
// Init vars
let dialogs = document.querySelectorAll('dialog');
let openButtons = document.querySelectorAll('.jsOpenDialog');
let closeButtons = document.querySelectorAll('.jsCloseDialog');
let defaultLocalStoragePopupData = {
	// shown: '',
	closed: '',
	submitted: ''
}

// Handle various click events for dialogs & their buttons
window.addEventListener("load", function () {

	// Store hotkeys
	const hotkeyMap = {};

	// Open Buttons
	openButtons.forEach(btn => {
		btn.addEventListener('click', (event) => {
			event.preventDefault();
			showDialog(btn.dataset.dialogId);
		});

		// Map hotkeys if defined
		const hotkey = btn.dataset.hotkey;
		if (hotkey) {
			hotkeyMap[hotkey] = btn.dataset.dialogId;
		}
	});

	// Listen for hotkey presses
	document.addEventListener("keydown", (event) => {
		// Ignore hotkeys if the user is typing in an input, textarea, or contenteditable element
		if (["INPUT","TEXTAREA","SELECT"].includes(event.target.tagName) || event.target.isContentEditable) {
			return;
		}

		const key = event.key;
		if (hotkeyMap[key]) {

			// Prevents the key from being typed
			event.preventDefault();

			showDialog(hotkeyMap[key]);
		}
	});

	// Close Buttons
	closeButtons.forEach(btn => {
		btn.addEventListener('click', () => {
			closeDialog(btn.dataset.dialogId);
		});
	});

	// Dialogs
	dialogs.forEach(dialog => {

		// Backdrop clicks
		dialog.addEventListener('click', (event) => {
			console.log(event.target.nodeName);

			if (event.target.nodeName === 'DIALOG') {
				closeDialog(dialog.id);
			}
		});

		// Auto-trigger conditions
		let currentTime = new Date();
		let storedCloseTime = getDialogClosedTime(dialog.id);
		if (storedCloseTime) {
			let difference = currentTime - storedCloseTime;
			let dialogCloseDurationInMs = dialog.dataset.closeDurationInDays * 24 * 60 * 60 * 1000;
			if (difference >= dialogCloseDurationInMs) {
				autotriggerPopup(dialog.id);
			}
		} else {
			autotriggerPopup(dialog.id);
		}
	});
});

// Show a specific dialog
function showDialog(id) {
	let dialog = document.getElementById(id);
	if (devMode) console.info(`Opening ${dialog.dataset.popupMode} dialog #${id}.`);

	try {
		switch (dialog.dataset.popupMode) {
			case 'corner':
				dialog.show();
				break;
			case 'centered':
				dialog.showModal();
				break;
			default:
				console.error(`Invalid popup mode "${dialog.dataset.popupMode}" on dialog #${id}`);
		}

		// Retrieve existing data from local storage (if any)
		// let popupData = JSON.parse(localStorage.getItem(id)) || defaultLocalStoragePopupData;

		// Get current date and time
		// let shownDate = new Date().toISOString();

		// popupData.shown = shownDate;

		// Store updated data
		// localStorage.setItem(id, JSON.stringify(popupData));
	} catch (error) {
		console.error(`Error in showing dialog #${id}`, error);
	}
}

// Close Dialogs
function closeDialog(id) {
	let dialog = document.getElementById(id);
	if (devMode) console.info(`Closing dialog #${id}.`);

	try {
		dialog.close();

		// Retrieve existing data from local storage (if any)
		let popupData = JSON.parse(localStorage.getItem(id)) || defaultLocalStoragePopupData;

		// Get current date and time
		let closedDate = new Date().toISOString();

		popupData.closed = closedDate;

		// Store updated data
		localStorage.setItem(id, JSON.stringify(popupData));
	} catch (error) {
		console.error(`Error in closing dialog #${id}`, error);
	}
}

// Popup auto-trigger
function autotriggerPopup(id) {
	let dialog = document.getElementById(id);
	if (dialog.dataset.triggerType === "timeElapsed")
	{
		// Trigger after a specific time delay
		setTimeout(() => {
			showDialog(id);
		}, dialog.dataset.timeElapsed * 1000);

	}
	else if (dialog.dataset.triggerType === "percentScrolled")
	{

		// Trigger when the user scrolls down a certain percentage of the page
		let triggered = false;

		window.addEventListener("scroll", function () {
			if (triggered) return;

			let scrollTop = window.scrollY;
			let docHeight = document.body.offsetHeight;
			let winHeight = window.innerHeight;
			let scrollPercent = scrollTop / (docHeight - winHeight);
			let scrollPercentRounded = Math.round(scrollPercent * 100);

			if (scrollPercentRounded >= dialog.dataset.percentScrolled) {
				triggered = true;
				showDialog(id);
			}
		});
	}
}

function isValidDate(date) {
	return date instanceof Date && !isNaN(date);
}

// Return a Date object of when the dialog was closed
function getDialogClosedTime(id) {
	// Retrieve existing data from local storage (if any)
	let popupData = JSON.parse(localStorage.getItem(id)) || defaultLocalStoragePopupData;
	if (popupData.closed) {
		try {
			let storedDate = new Date(popupData.closed);
			if (!isValidDate(storedDate)) {
				throw new Error('Invalid Date');
			}
			return storedDate;
		} catch (error) {
			console.error(error.message);
			return false;
		}
	} else {
		return false;
	}
}